<template>
  <ul class="ul-main-menu">
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.name"
      :item="item"
    />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    return {
      resolveNavItemComponent,
    }
  },
}
</script>

<style lang="scss" scoped>
.ul-main-menu {
  max-height: 72vh;
  overflow-y: scroll;
  overflow-x: hidden;

  // Extra small (xs)
  @media (max-width: 575.98px)  {
  }

  // Small (sm)
  @media (min-width: 576px) and (max-width: 767.98px) {
  }

  // Medium (md)
  @media (min-width: 768px) and (max-width: 991.98px) {
  }

  // Large (lg)
  @media (min-width: 992px) and (max-width: 1199.98px) {
  }

  // Extra large (xl)
  @media (min-width: 1200px) {
    max-height: 69vh;
  }

  // Extra extra large (xxl)
  @media (min-width: 1400px) {
  }
}
</style>
