<template>
  <b-nav-item-dropdown
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <b-modal
      id="modal-1"
      centered
      hide-footer
      title="Login To Another Account"
    >
      <div class="px-6">
        <LoginForm
          v-if="!show_two_factor"
          :is-hiring-manager="true"
          @other-profile-update="otherProfileStatus"
        />
        <TwoFactor
          v-else
          :is-hiring-manager="true"
          @other-profile-update="otherProfileStatus"
        />
      </div>

    </b-modal>
    <div
      v-if="false"
      class="flex mr-10"
    >
      <div
        v-for="(profile, idx) in otherProfiles"
        :key="idx"
        class=""
      >
        <b-dropdown
          vs-custom-content
          vs-trigger-click
          class="cursor-pointer"
        >
          <vx-tooltip
            position="bottom"
            text="Click here to view more options."
          >
            <b-avatar
              class="rounded-full shadow-md cursor-pointer block text-xl"
              variant="primary"
              :text="profile.fullname"
              size="40px"
            />
          </vx-tooltip>

          <b-dropdown-menu class="vx-navbar-dropdown">
            <ul style="min-width: 12rem">
              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="switchAccount(idx)"
              >
                <feather-icon
                  icon="ShuffleIcon"
                  svg-classes="w-10 h-4"
                />
                <span class="ml-2">Switch to this account</span>
              </li>
              <hr>
              <hr>

              <li
                class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
                @click="logout(idx)"
              >
                <feather-icon
                  icon="LogOutIcon"
                  svg-classes="w-10 h-4"
                />
                <span class="ml-2">Logout</span>
              </li>
            </ul>
          </b-dropdown-menu>
        </b-dropdown>
      </div>
    </div>
    <template #button-content>
      <div class="d-none d-flex user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ activeUserInfo.fullname }}
        </p>
      </div>
        <!-- v-if="activeUserInfo" -->
      <b-avatar
        v-if="false"
        size="40"
        square
        :src="
          activeUserInfo.profile_image == null
            ? require('@/assets/images/placeholder/no-image.jpg')
            : activeUserInfo.profile_image_url
        "
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!activeUserInfo.fullname"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-item
      to="/profile"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="UserIcon"
        class="mr-50"
      />
      <span>Profile</span>
    </b-dropdown-item>

    <b-dropdown-divider />
    <!-- <b-dropdown-item
      v-if="activeUserInfo.user_group && activeUserInfo.account_type === this.$AccountTypes.APPLICANT"
      link-class="d-flex align-items-center"
      @click="addAccount"
    >
      <feather-icon
        size="16"
        icon="UserPlusIcon"
        class="mr-50"
      />
      <span>Add Account</span>
    </b-dropdown-item> -->
    <b-dropdown-item
      v-if="
        activeUserInfo
          && activeUserInfo.user_group.includes(this.$UserGroups.SUPERUSER)
          && activeUserInfo.account_type != $AccountTypes.AGENCY
      "
      link-class="d-flex align-items-center"
      to="/corporate-profile"
    >
      <feather-icon
        size="16"
        icon="GlobeIcon"
        class="mr-50"
      />
      <span>Corporate Profile</span>
    </b-dropdown-item>
    <b-dropdown-item
      to="/settings"
      link-class="d-flex align-items-center"
    >
      <feather-icon
        size="16"
        icon="SettingsIcon"
        class="mr-50"
      />
      <span>Settings</span>
    </b-dropdown-item>
    <b-dropdown-item
      id="buttonLogout"
      link-class="d-flex align-items-center"
      @click="logout(null)"
    >
      <feather-icon
        size="16"
        icon="LogOutIcon"
        class="mr-50"
      />
      <span>Logout </span>
    </b-dropdown-item></b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import LoginForm from '@/views/authentication/login/LoginForm.vue'
import TwoFactor from '@/views/authentication/login/TwoFactor.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    LoginForm,
    TwoFactor,
  },
  data() {
    return {
      show_login: false,
      show_two_factor: false,
      activeUserInfo: JSON.parse(localStorage.getItem('userInfo')),
      avatarText,
    }
  },
  computed: {
    // activeUserInfo() {
    //   console.log(this.$store.state.auth.ActiveUser || JSON.parse(localStorage.getItem('userInfo')))
    //   return this.$store.state.auth.ActiveUser || JSON.parse(localStorage.getItem('userInfo'))
    // },

    otherProfiles() {
      return this.$store.state.auth.OtherProfiles
    },
    profileImageLink() {
      const fileUrl = `${this.$bucketUrl}/${this.activeUserInfo.profile_image}`
      return fileUrl
    },
  },
  mounted() {
    this.activeUserInfo = this.$store.state.auth.ActiveUser || JSON.parse(localStorage.getItem('userInfo'))
    this.$store.subscribeAction({
      after: (action, state) => {
        if (
          action.type === 'auth/loginJWT'
          || action.type === 'auth/approveLogin'
        ) {
          this.show_login = false
        }
      },
      error: (action, state, error) => {
        if (
          action.type === 'auth/loginJWT'
          && error.message === 'Two factor code required'
        ) {
          this.show_two_factor = true
        }
      },
    })
  },
  methods: {
    logout(index = null, $event) {
      if (index !== null) {
        const OtherProfiles = [...this.otherProfiles]
        OtherProfiles.splice(index, 1)
        this.$store.commit('auth/CHANGE_MULTIPLE_PROFILES', OtherProfiles, {
          root: true,
        })

        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Account Information',
              icon: 'AlertCircleIcon',
              text: 'You have logged out of the account successfully',
              variant: 'success',
            },
          },
          { timeout: this.$longestTimeout },
        )
      } else if (this.otherProfiles.length > 0) {
        const OtherProfiles = [...this.otherProfiles]
        const profileTo = this.$store.state.OtherProfiles[0]

        OtherProfiles.splice(0, 1)

        this.$store.commit('auth/CHANGE_MULTIPLE_PROFILES', OtherProfiles, {
          root: true,
        })

        this.$store.commit('auth/UPDATE_USER_INFO', profileTo, { root: true })
        localStorage.setItem('accessToken', profileTo.token)
        window.location.reload()
      } else {
        localStorage.clear()
        this.$store.commit('auth/UPDATE_USER_INFO', null, { root: true })
        localStorage.removeItem('userInfo')
        localStorage.removeItem('email')
        localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)


        // This is just for demo Purpose. If user clicks on logout -> redirect
        setTimeout(() => {
          delete this.$http.defaults.headers.common['Authorization']

          // this.$router.push({ name: 'auth-login' })
          const hostUrl = window.location.origin
          const route = this.$router.resolve({name: 'auth-login'}).href
          window.location.replace(hostUrl+route)
        }, 550) // * TO COUNTER THE DEBOUNCE ON CLICK TRACKING ON trackingMixins.js, ELSE IT WILL SAVE THE LOGOUT CLICK TO A NEW SESSION INSTEAD
      }
    },

    profile() {
      this.$router.push('/profile').catch(() => {})
    },

    gotoCorporateProfile(event) {
      this.$emit('clicked', false, this.profile_type)
    },

    addAccount() {
      this.show_login = true
    },
    otherProfileStatus(args) {
      if (args.status === 'login') {
        this.show_two_factor = false
      }
    },

    switchAccount(index) {
      const OtherProfiles = [...this.otherProfiles]
      const profileTo = this.$store.state.OtherProfiles[index]

      const profileFrom = this.$store.state.AppActiveUser

      OtherProfiles.splice(index, 1, profileFrom)

      this.$store.commit('auth/CHANGE_MULTIPLE_PROFILES', OtherProfiles, {
        root: true,
      })

      this.$store.commit('auth/UPDATE_USER_INFO', profileTo, { root: true })

      localStorage.setItem('accessToken', profileTo.token)

      window.location.reload()
    },
  },
}
</script>
