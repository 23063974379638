<template>
  <div
    class="main-menu menu-fixed menu-accordion menu-shadow"
    :class="[
      {
        expanded:
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered),
      },
      skin === 'semi-dark' ? 'menu-dark' : 'menu-light',
    ]"
    @mouseenter="updateMouseHovered(true)"
    @mouseleave="updateMouseHovered(false)"
  >
    <!-- main menu header-->
    <div class="navbar-header expanded">
      <slot
        name="header"
        :toggleVerticalMenuActive="toggleVerticalMenuActive"
        :toggleCollapsed="toggleCollapsed"
        :collapseTogglerIcon="collapseTogglerIcon"
      >
        <ul class="nav navbar-nav flex-row">
          <!-- Logo & Text -->
          <li class="nav-item mr-auto my-1">
            <router-link :to="{name: 'listings-event'}">
              <b-img
                :src="appLogoImage"
                height="40px"
                width="auto"
                alt="logo"
                class=""
              />
            </router-link>
            <!-- <b-link
              class="navbar-brand"
              to="/"
            >
              <span class="brand-logo">
                <b-img
                  :src="appLogoImage"
                  height="100"
                  width="200"
                  alt="logo"
                />
              </span>
            </b-link> -->
          </li>

          <!-- Toggler Button -->
          <li class="nav-item nav-toggle">
            <b-link class="nav-link modern-nav-toggle">
              <feather-icon
                icon="XIcon"
                size="20"
                class="d-block d-xl-none"
                @click="toggleVerticalMenuActive"
              />
              <feather-icon
                :icon="collapseTogglerIconFeather"
                size="20"
                class="d-none d-xl-block collapse-toggle-icon"
                @click="toggleCollapsed"
              />
            </b-link>
          </li>
        </ul>
      </slot>
    </div>
    <!-- / main menu header-->

    <!-- Shadow -->
    <div
      :class="{ 'd-block': shallShadowBottom }"
      class="shadow-bottom"
    />

    <!-- main menu content-->
    <vue-perfect-scrollbar
      :settings="perfectScrollbarSettings"
      class="main-menu-content scroll-area"
      tagname="ul"
      @ps-scroll-y="(evt) => {
        shallShadowBottom = evt.srcElement.scrollTop > 0;
      }
      "
    >
      <vertical-nav-menu-items
        :items="updatedMenu"
        class="navigation navigation-main"
      />

      <div
        v-if="
          !isVerticalMenuCollapsed ||
          (isVerticalMenuCollapsed && isMouseHovered)
        "
        class="bg-white fixed-bottom my-0 py-0"
      >
        <div
          v-if="$store.state.auth.ActiveUser &&
            $store.state.auth.ActiveUser.email_address.includes('hhqmail')
          "
          class="container mb-6"
        >
          <p class="text-dark mt-1">
            For any Question or Feedback:
          </p>
          <!-- <p>
            <b-link
              href="mailto:jobsecurity@e2i.com.sg"
              class="mb-8"
            >
              jobsecurity@e2i.com.sg
            </b-link>
            <br>
            <span class="text-dark mt-2">or</span>
            <b-link href="tel:+6562067282">
              +65 6206 7282
            </b-link>
          </p> -->
          <p class="text-dark">
            For Technical Support:
          </p>
          <b-link
            href="mailto:support@headhuntershq.com"
            class="mb-8"
          >
            support@headhuntershq.com
          </b-link>
          <br>
          <br>
        </div>
        <div
          v-else
          class="m-0 px-1 pb-1"
        >
          <p class="text-dark mt-1 mb-0">
            For Feedback & Enquiry:
          </p>
          <b-link
            href="mailto:talent@designsingapore.org"
            class="mb-1"
          >talent@designsingapore.org</b-link>
          <p class="text-dark mt-1 mb-0">
            For Technical Support:
          </p>
          <b-link
            href="mailto:support@headhuntershq.com"
            class="mb-1"
          >support@headhuntershq.com</b-link>
        </div>
      </div>
    </vue-perfect-scrollbar>

    <!-- /main menu content-->
  </div>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { BLink, BImg } from 'bootstrap-vue'
import {
  provide,
  computed,
  ref,
  onBeforeMount,
  getCurrentInstance,
} from '@vue/composition-api'
import useAppConfig from '@core/app-config/useAppConfig'
import { $themeConfig } from '@themeConfig'
import navMenuItems from '@/navigation/vertical'
import store from '@/store'
import router from '@/router'
import { compact } from 'lodash'
import VerticalNavMenuItems from './components/vertical-nav-menu-items/VerticalNavMenuItems.vue'
import useVerticalNavMenu from './useVerticalNavMenu'

export default {
  components: {
    VuePerfectScrollbar,
    VerticalNavMenuItems,
    BLink,
    BImg,
  },
  props: {
    isVerticalMenuActive: {
      type: Boolean,
      required: true,
    },
    toggleVerticalMenuActive: {
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const {
      isMouseHovered,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      updateMouseHovered,
    } = useVerticalNavMenu(props)

    const { skin } = useAppConfig()

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)
    const updatedMenu = ref([])

    provide('isMouseHovered', isMouseHovered)

    // const getUser = computed(() => ())
    const app = getCurrentInstance()
    const instance = app.proxy

    onBeforeMount(() => {
      function menuItemsUpdated() {
        const allNavMenuItems = navMenuItems

        const profile = JSON.parse(localStorage.getItem('userInfo'))

        let authGroup = 'global'

        if (profile && profile.user_group) {
          authGroup = profile.user_group
          authGroup.push(profile.account_type)
        }

        // const allAllowedSections = profile.permissions.map(permission => {
        //   if (permission.read) {
        //     return permission.section
        //   }
        // })
        let allAllowedSections = []
        if (profile.account_type === 'agency') {
          allAllowedSections = [
            "Analytics",
            "Audit Log",
            // "Corporate Directory",
            "Data Manager",
            // "Events Manager",
            // "Finance",
            // "Recruitment Chatbot",
            "User Manager",
            "Calendar",
            "Resource",
            "ATS System",
            "Notifications",
          ]
        } else if (profile.account_type === 'employer') {
          allAllowedSections = [
            "Audit Log",
            "Data Manager",
            "User Manager",
            "Calendar",
            "Resource",
            "ATS System",
            "Notifications",
          ]
        } else {
          allAllowedSections = [
            "Audit Log",
            "Data Manager",
            "User Manager",
            "ATS System",
            "Notifications",
          ]
        }

        const { company_name } = profile

        const arrayHasSomeSimilar = (firstArray, secondArray) => secondArray.some(item => firstArray.includes(item))
        // filter by group

        const publicPages = ['notifications', 'settings']

        let modifiedMenuItems = allNavMenuItems.filter(
          menuItem => publicPages.includes(menuItem.section)
            || arrayHasSomeSimilar(authGroup, menuItem.group),
        )

        modifiedMenuItems = modifiedMenuItems.map(menuItem => {
          let result = null
          if (menuItem.section !== undefined) {
            if (allAllowedSections.includes(menuItem.section)) {
              result = menuItem
            }
          }
          if (menuItem.allowed_companies !== undefined) {
            if (menuItem.allowed_companies.includes(company_name)) {
              result = menuItem
            }
          } else if (menuItem.header) {
            result = menuItem
          } else if (authGroup.includes('applicant')) {
            if (menuItem.title !== 'Referrals') {
              result = menuItem
            }
          } else if (menuItem.section === 'notifications') {
            result = menuItem
          }


          if (result) {
            if ('children' in result) {
              let newChildren = []
              result.children.forEach(child => {
                if (child.group.some(group => authGroup.includes(group))) {
                  newChildren.push(child)
                }

              })
              result.children = newChildren
            }

            return result
          }
        })
        modifiedMenuItems = compact(modifiedMenuItems)

        return modifiedMenuItems
      }
      updatedMenu.value = menuItemsUpdated()
      const { email_address } = store.state.auth.ActiveUser || {}
      instance.$socket.client.emit('user_online', email_address)
      instance.$socket.client.on('all_online_users', data => {
        store.commit('auth/CHANGE_ONLINE_USERS', data.data)
      })

      instance.$socket.client.on('logout_notice', data => {
        if (
          data.email_address.toLowerCase() === email_address.toLowerCase()
          && data.user_agent !== navigator.userAgent
        ) {
          localStorage.removeItem('userInfo')
          localStorage.removeItem('accessToken')
          router.push('/')
        }
      })

      instance.$socket.client.on('received_video_call_requests', data => {
        store.dispatch('auth/updateIncomingInterview', data)
      })
    })

    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    const collapseTogglerIconFeather = computed(() => (collapseTogglerIcon.value === 'unpinned' ? 'CircleIcon' : 'DiscIcon'))

    // App Name
    const { appName, appLogoImage } = $themeConfig.app

    return {
      updatedMenu,
      perfectScrollbarSettings,
      isVerticalMenuCollapsed,
      collapseTogglerIcon,
      toggleCollapsed,
      isMouseHovered,
      updateMouseHovered,
      collapseTogglerIconFeather,

      // Shadow Bottom
      shallShadowBottom,

      // Skin
      skin,

      // App Name
      appName,
      appLogoImage,
    }
  },
  watch: {
    isMouseHovered(val) {
      if (val) {
        this.appLogoImage = require('@/assets/images/logo/designsingapore-logo-white.png')
      } else {
        if (this.isVerticalMenuCollapsed) {
          this.appLogoImage = require('@/assets/images/logo/designsingapore-logo-white-cropped.png')
        }
      }
    },
    isVerticalMenuCollapsed(val) {
      if (val) {
        this.appLogoImage = require('@/assets/images/logo/designsingapore-logo-white-cropped.png')
      } else {
        this.appLogoImage = require('@/assets/images/logo/designsingapore-logo-white.png')
      }
    }
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/core/menu/menu-types/vertical-menu.scss";
</style>


<style lang="scss" scoped>
.scroll-menu {
}
</style>
