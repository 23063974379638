<template>
  <p class="clearfix mb-0 text-din-regular text-pantone-445">
    <span class="float-md-left d-block d-md-inline-block mt-25">
      <span>An initiative of</span>
      <b-link
        class="text-pantone-445"
        href="https://designsingapore.org"
        target="_blank"
        rel="nofollow"
      > DesignSingapore Council</b-link>
      <span> © 2023. </span>

      <span>Powered by
        <b-link
          class="text-pantone-445"
          href="https://headhuntershq.com"
          target="_blank"
          rel="nofollow"
        >HeadHunters HQ</b-link>
      </span>

      <span class="d-sm-inline-block">&nbsp;|&nbsp;</span>
      <b-link
        class="text-pantone-445"
        href="https://designsingapore.org/privacy-statement/"
        target="_blank"
        rel="nofollow"
      >Privacy Statement</b-link>
      <span class="d-sm-inline-block">,&nbsp;</span>
      <b-link
        class="text-pantone-445"
        href="https://designsingapore.org/terms-of-use/"
        target="_blank"
        rel="nofollow"
      >Terms of Use</b-link>
    </span>
  </p>
</template>

<script>
import { BLink } from 'bootstrap-vue'

export default {
  components: {
    BLink,
  },
}
</script>
